import React, { Component } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import packageJson from "../../../package.json";
import { connect } from "react-redux";
import Collection from "../../pages/private/collection/Collection";
import User from "../../pages/private/user/User";
import CreateUser from "../../pages/private/user/CreateUser";
import UpdateUserInfo from "../../pages/private/user/UpdateUserInfo";
import CollectionDetails from "../../pages/private/collection/collectionDetails/CollectionDetails";
import Faq from "../../pages/private/faq/Faq";
import ShowFaq from "../../pages/private/faq/ShowFaq";
import UpdateFaq from "../../pages/private/faq/UpdateFaq";
import Commission from "../../pages/private/commission/Commission";
import ContractList from "../../pages/private/contracts/ContractList";
import Terms from "../../components/TermsConditions/Terms";
import Header from "../../components/Header/Header";
import LeftSectionHeadings from "../../components/Homepage/Headings/LeftSectionHeadings";
import Testimonial from "../../components/testimonial/Testimonial";

class PrivateRoutes extends Component {
  componentDidMount = async () => {
    const { loggedIn } = this.props;
    console.log(
      packageJson.version,
      "=========packageJson===================="
    );
    if (loggedIn) {
      if (
        localStorage.getItem("REACT_CACHE") === null ||
        localStorage.getItem("REACT_CACHE") === undefined
      ) {
        window.localStorage.clear();
        window.location.reload();
      } else {
        console.log(
          localStorage.getItem("REACT_CACHE"),
          "==================else=========="
        );
        if (packageJson.version !== localStorage.getItem("REACT_CACHE")) {
          console.log("==================if2==========");
          window.localStorage.clear();
          window.location.reload();
        }
      }
    }
  };

  render() {
    return (
      <div className="PrivateArea__content">
        <Route path="/" component={Collection} exact />
        <Route path="/users" component={User} exact />
        <Route path="/users/createUser" component={CreateUser} exact />
        <Route path="/addFaq" component={Faq} exact />
        <Route path="/faq" component={ShowFaq} exact />
        <Route path="/updateFaq" component={UpdateFaq} exact />
        <Route
          path="/users/updateUserInfo/:userId"
          component={UpdateUserInfo}
          exact
        />
        <Route
          path="/collection/details/:collectionId"
          component={CollectionDetails}
          exact
        />
        <Route path="/commission" component={Commission} exact />
        <Route path="/contracts" component={ContractList} exact />
        <Route path="/terms" component={Terms} exact />
        <Route path="/homepage/headings" exact component={LeftSectionHeadings} />
        <Route path="/homepage/testimonials" component={Testimonial} exact />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.persist.loggedIn,
  };
};

export default connect(mapStateToProps, null)(withRouter(PrivateRoutes));
