import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import { Button, Container, Form } from "semantic-ui-react";
import {
  addOrUpageHeaderLeftText,
  getHeaderValues,
} from "../../../redux/_actions/homepage.action";
import MainLayout from "../../HOC/MainLayout";

function LeftSectionHeadings() {
  const [header, setHeader] = useState({
    headingOne: "",
    headingTwo: "",
  });

  const [paragraph, setParagraph] = useState("");

  const handleHeaderParagraph = (e) => {
    setParagraph(e);
  };

  const dispatch = useDispatch();

  const handleHomepageHeaders = async (e) => {
    try {
      const data = {
        header: {
          ...header,
          paragraph,
        },
      };

      await dispatch(addOrUpageHeaderLeftText(data));
    } catch (error) {
      console.log(error);
    }
  };

  const handleHeadings = (e) => {
    const { name, value } = e.target;
    setHeader({
      ...header,
      [name]: value,
    });
  };

  const getHeaders = async () => {
    try {
      let res = await dispatch(getHeaderValues());
      if (res.data.data) {
        setHeader((obj) => {
          return {
            ...obj,
            ...res.data.data.header,
          };
        });

        setParagraph(res.data.data.header.paragraph);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHeaders();
  }, []);

  useEffect(() => {
    // console.log(header);
  }, [header]);

  return (
    <MainLayout>
      <Container>
        <h3>Header section</h3>
        <Form onSubmit={handleHomepageHeaders}>
          <Form.Field>
            <label>Title</label>
            <input
              placeholder="Heading 1"
              name="headingOne"
              value={header.headingOne}
              onChange={(event) => handleHeadings(event)}
            />
          </Form.Field>
          <Form.Field>
            <label>SubTitle</label>
            <input
              placeholder="Heading 2"
              name="headingTwo"
              value={header.headingTwo}
              onChange={(event) => handleHeadings(event)}
            />
          </Form.Field>

          <Form.Field>
            <label>Content</label>
            <ReactQuill
              theme="snow"
              onChange={handleHeaderParagraph}
              value={paragraph}
              name="paragraph"
            />
          </Form.Field>

          <Button type="submit">Update</Button>
        </Form>
      </Container>
    </MainLayout>
  );
}

export default LeftSectionHeadings;
