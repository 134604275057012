import { LoadingActions, PersistActions } from ".";
import ApiService from "../../Services/Api.service";
const { startLoading, stopLoading } = LoadingActions;
/**
 * @function addTestimonials
 * @param {image,fileName,content,isShow} data
 * @returns /admins/testimonials/addTestimonial Api response
 */

export const addTestimonials = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    dispatch(stopLoading());
    return ApiService.addTestimonial(data, headers);
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const updateTestimonial =
  (testimonialId, data) => async (dispatch, getState) => {
    const { startLoading, stopLoading } = LoadingActions;
    try {
      dispatch(startLoading());
      const { setHeaders } = PersistActions;
      const headers = await dispatch(setHeaders({ isFormdata: false }));
      dispatch(stopLoading());
      return ApiService.updateTestimonial(testimonialId, data, headers);
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };

export const deleteTestimonial =
  (testimonialId) => async (dispatch, getState) => {
    const { startLoading, stopLoading } = LoadingActions;
    try {
      dispatch(startLoading());
      const { setHeaders } = PersistActions;
      const headers = await dispatch(setHeaders({ isFormdata: false }));
      dispatch(stopLoading());
      return ApiService.deleteTestimonial(testimonialId, headers);
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };
/**
 * @function getTestimonials
 * @param {isShow,page,limit} data
 * @returns testimonials data
 */

export const getTestimonials = (data) => async (dispatch, getState) => {
  try {
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    return ApiService.getTestimonials(data, headers);
  } catch (error) {
    throw error;
  }
};
