import React,{useCallback, useEffect, useState} from 'react';
import "../../public/login/login.scss";
import { connect, useDispatch } from "react-redux";
import { UserActions } from "../../../redux/_actions";
import { Field, reduxForm } from "redux-form";
import { FormField } from "../../../components/FormField";
import { required } from "redux-form-validators";
import { Container, Form } from "semantic-ui-react";
import MainLayout from "../../../components/HOC/MainLayout";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { compose } from 'redux';


const UpdateUserInfo = ({ handleSubmit, history }) => {

  const userDetails = useSelector(state => state.user.user);

  const { userId } = useParams();
  const dispatch = useDispatch();

  const [address,setAddress] = useState();

  const submitForm = async (data) => {
    const { updateUserInfo } = UserActions;
    await dispatch(updateUserInfo(data));
    history.push('/users');
  };

  const getUser = useCallback(
    async (data) => {
      try {
        const res = await dispatch(UserActions.getUserDetails(data));
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getUser(userId);
  }, []);

  useEffect(()=>{
    console.log(userDetails,"in useEffect");
    setAddress(userDetails.address);
  },[userDetails]);

    return (
        <MainLayout>
          <Container fluid>
            <Container>
              <div>
                <div>
                  <Form
                    autoComplete="off"
                    autoFocus="off"
                    noValidate
                    onSubmit={handleSubmit(submitForm)}
                  >
                    <Form.Field>
                      <label>Email</label>
                      <Field
                        component={FormField}
                        name="email"
                        type="email"
                        placeholder="Email"
                        validate={[required()]}
                      />
                    </Form.Field>
    
                    <Form.Field>
                      <label>Wallet Address</label>
                      <Field
                        component={FormField}
                        type="text"
                        name="walletAddress"
                        placeholder="Enter wallet"
                        validate={[required()]}
                      />
                    </Form.Field>
    
                    <Form.Field>
                      <label >Country Code</label>
                      <select name="countryCode" id="cars">
                        <option value="+91">+91</option>
                        <option value="+1">+1</option>
                        <option value="+86">+86</option>
                      </select>
                    </Form.Field>
    
                    <Form.Field>
                      <label>Phone Number</label>
                      <Field
                        component={FormField}
                        type="tel"
                        name="address"
                        placeholder="Phone Number"
                        validate={[required()]}
                      />
                    </Form.Field>
    
                    <Form.Field>
                      <label>Home Address</label>
                      <Field
                        component={FormField}
                        type="text"
                        name="phoneNumber"
                        placeholder="Home Address"
                        validate={[required()]}
                      />
                    </Form.Field>
    
                    <Form.Field>
                      <button type="submit" primary="true">
                        Submit
                      </button>
                    </Form.Field>
                  </Form>
                </div>
              </div>
            </Container>
          </Container>
        </MainLayout>
      );

};

const mapStateToProps = (state) => ({
  initialValues: state?.user?.user,
});

export default compose(
  connect(mapStateToProps, null),
  reduxForm({ form: "AddUserForm", enableReinitialize: true })
)(UpdateUserInfo);