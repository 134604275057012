import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import MainLayout from "../../../components/HOC/MainLayout";
import { CollectionActions } from "../../../redux/_actions";
import GlobalVariables from "../../../_constants/GlobalVariables";
import CollectionList from "./list/CollectionList";

const Collection = () => {
  const dispatch = useDispatch();
  const [collections, setCollections] = useState({
    totalCounts: 0,
    list: [],
  });
  const [filters, setFilters] = useState(
    GlobalVariables.INIT_COLLECTION_FILTERS
  );


  const getCollections = async (data) => {
    console.log("on filter ",data);
      const { getCollections } = CollectionActions;
      const res = await dispatch(getCollections(data));
      if (res) {
        const {
          data: { data },
        } = res;
        setCollections(data);
      }
    };

  useEffect(() => {
    if (filters.isFiltered) {
     
      getCollections({ page: 0, limit: 5000,  filters });
    } else {
      getCollections({ page: 0, limit: GlobalVariables.PAGE_LIMIT, filters });
    }
  }, [filters.isFiltered]);

  return (
    <MainLayout>
      <h3>Collections </h3>
      {/* <Filter setFilters={setFilters} filters={filters} /> */}
      <CollectionList
        getCollections={getCollections}
        collections={collections}
      />
    </MainLayout>
  );
};

export default withRouter(Collection);
