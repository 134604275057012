import { combineReducers } from "redux";
import loading from "./loading.reducer";
import { createBrowserHistory } from "history";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";
import persist from "./persist.reducer";
import collection from "./collection.reducer copy";
import banner from "./banner.reducer";
import user from "./user.reducer";
import commission from "./commission.reducer";
export const history = createBrowserHistory();


const appReducer = combineReducers({
  loading,
  persist,
  form: formReducer,
  collection: collection,
  banner: banner,
  router: connectRouter(history),
  user: user,
  commission: commission,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;
