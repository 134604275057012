import { fetch } from "./Fetch";
import Enviroments from "../_constants/Enviroment";

const { API_HOST } = Enviroments;

const login = (data, headers = {}) => fetch("post", `${API_HOST}/v1/users/user/login`, data, headers, true);
const addUser = (data, headers) => fetch("post", `${API_HOST}/v1/admins/user/addUser`, data, headers, true);
const getAllUser = (data, header) => fetch("post", `${API_HOST}/v1/users/user/getAllUsers`, data, header);
const disableUser = (data, header) => fetch("put", `${API_HOST}/v1/admins/user/disableUser`, data, header, true);
const deleteUser = (data, header) => fetch("delete", `${API_HOST}/v1/admins/user/deleteUser/${data._id}`, `${API_HOST}/v1/admins/users/admin/deleteUser/${data._id}`, {}, header);
const updateUserInfo = (data, header) => fetch("put", `${API_HOST}/v1/users/user/updateUserInfo`, data, header, true);
const getUserDetails = (data) => fetch("get", `${API_HOST}/v1/users/user/getUserDetails/${data}`);
const addFaq = (data, header) => fetch("put", `${API_HOST}/v1/admins/faq/addFaq`, data, header, true);
const showFaq = (data, header) => fetch("post", `${API_HOST}/v1/admins/faq/fetchFaq`, data, header, true);
const deleteFaq = (data, header) => fetch("post", `${API_HOST}/v1/admins/faq/deleteFaq`, data, header, true);
const updateFaq = (data, header) => fetch("post", `${API_HOST}/v1/admins/faq/updateFaq`, data, header, true);
const getCollections = (data, headers) => fetch("post", `${API_HOST}/v1/admins/collection/getAllCollections`, data, headers, true);
const getCollectionDetails = (data, headers) => fetch("get", `${API_HOST}/v1/nfts/collection/getCollectionDetails/${data.id}`, {}, headers);
const approveOrDeclineCollection = (data, headers) => fetch("put",`${API_HOST}/v1/admins/collection/approveOrDeclineCollection`, data, headers, true);
const createCollection = (data, headers) => fetch("post", `${API_HOST}/v1/admins/admin/collections/add`, data, headers);
const getCollectionById = (data, headers) => fetch("get", `${API_HOST}/v1/admins/admin/collections/${data.id}`, {}, headers);
const updateCollection = (data, headers) => fetch("put",`${API_HOST}/v1/admins/admin/collections/${data.id}`, data, headers);
const getCommission = (data, headers) => fetch("get", `${API_HOST}/v1/admins/commission/getCommission`, data, headers);
const updateCommission = (data, headers) => fetch("post",`${API_HOST}/v1/admins/commission/updateCommission`, data, headers, true);
const createNft = (data, headers) => fetch("post", `${API_HOST}/v1/admins/admin/addNft`, data, headers);
const getNfts = (data, headers) => fetch("get", `${API_HOST}/v1/admins/admin/list/${data.limit}/${data.page}`, {} , headers);
const createBanner = (data, headers) => fetch("post", `${API_HOST}/admin/api/v1/banner/add`, data, headers);
const getBanner = (data, headers) => fetch("get", `${API_HOST}/admin/api/v1/banner/list/${data.limit}/${data.page}`, {}, headers);
const getBannerById = (data, headers) => fetch("get", `${API_HOST}/admin/api/v1/banner/${data.id}`, {}, headers);
const updatebanner = (data, headers) => fetch("put", `${API_HOST}/admin/api/v1/banner/${data.id}`, data, headers);
const uploadImage = (data, headers) => fetch("post", `${API_HOST}/admin/api/v1/upload/banner`, data, headers);
const getCollectionNfts = (data, headers) => fetch("post", `${API_HOST}/v1/nfts/nft/getCollectionNfts`, data, headers);
const getContracts = (headers) => fetch("get", `${API_HOST}/v1/nfts/contract/getContracts`, {}, headers);
const disableEnableCollection = (data, headers) => fetch("put", `${API_HOST}/v1/admins/collection/disableEnable`, data, headers, true);
const createTermsPolicy = (data, headers) => fetch("post", `${API_HOST}/v1/admins/termsCondition/createTermspolicy`, data, headers, true);
const getTermsCondition = (data, headers) => fetch("get", `${API_HOST}/v1/admins/termsCondition/getTermsCondition`, {}, headers);
const getHeaderValues = (data, headers) => fetch("get", `${API_HOST}/v1/admins/homepage/getHeaderValues`, {}, headers);
const addOrUpageHeaderLeftText = (data, headers) => fetch("post", `${API_HOST}/v1/admins/homepage/addOrUpageHeaderLeftText`, data, headers, true);
const addTestimonial = (data, headers) => fetch("post", `${API_HOST}/v1/admins/testimonial/addTestimonial`, data, headers, true);
const updateTestimonial = (testimonialId, data, headers) => fetch("put",`${API_HOST}/v1/admins/testimonial/updateTestimonial/${testimonialId}`, data, headers, true);
const deleteTestimonial = (testimonialId, headers) => fetch( "delete", `${API_HOST}/v1/admins/testimonial/deleteTestimonial/${testimonialId}`, {}, headers);
const getTestimonials = (data, headers) => fetch("post", `${API_HOST}/v1/admins/testimonial/getTestimonials`, data, headers, true);
const dragDropFaq = (data, headers) => fetch("put", `${API_HOST}/v1/admins/faq/dragDrop`, data, headers, true);

const ApiService = {
  login,
  getCollections,
  uploadImage,
  createCollection,
  getCollectionById,
  updateCollection,
  createNft,
  getNfts,
  createBanner,
  getBanner,
  getBannerById,
  updatebanner,
  getAllUser,
  addUser,
  disableUser,
  deleteUser,
  updateUserInfo,
  getUserDetails,
  getCollectionDetails,
  addFaq,
  showFaq,
  approveOrDeclineCollection,
  getCommission,
  deleteFaq,
  updateFaq,
  getCollectionNfts,
  updateCommission,
  getContracts,
  disableEnableCollection,
  createTermsPolicy,
  getTermsCondition,
  getHeaderValues,
  addOrUpageHeaderLeftText,
  addTestimonial,
  updateTestimonial,
  deleteTestimonial,
  getTestimonials,
  dragDropFaq
};

export default ApiService;
