import DragDropService from "./DragDrop.service";

class DragDropFacadeService {
  constructor() {
    this.service = new DragDropService();
  }

  setDragPosition(position) {
    this.service.dragPosition = position;
    return this;
  }
  setDropPosition(position) {
    this.service.dragOverPosition = position;
    return this;
  }
  build(drag, drop){
    return this.setDragPosition(drag).setDropPosition(drop)
  }
  dropAndCreateApiData(drag, drop, list) {
    let builder = this.build(drag,drop)
    let items = builder.service.drop(list);
    return {
      apiList: this.service.createDragDropApiData(items),
      newList: items,
    };
  }
}

export default Object.seal(new DragDropFacadeService());
