import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { Button } from "semantic-ui-react";
import MainLayout from "../../../components/HOC/MainLayout";
import { UserActions } from "../../../redux/_actions";
import GlobalVariables from "../../../_constants/GlobalVariables";
import UserList from "./list/UserList";

const User = ({ history }) => {
  const gotoAddUser = () => {
    history.push("/users/createUser");
  };
  const dispatch = useDispatch();
  const [users, setUsers] = useState({
    list: [],
    totalCounts: 0,
  });

  const disableUser = async (_data) => {
    console.log(_data);
    try {
      const res = await dispatch(UserActions.disableUser(_data));
      // setUsers(res.data.data);
      getAllUsers();
    } catch (error) {
      console.log(error);
    }
  };
  
  const deleteUser = async (_data) => {
    try {
      const res = await dispatch(UserActions.deleteUser(_data));
      getAllUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const getAllUsers = useCallback(
    async (_data={ page: 0,
      limit: GlobalVariables.PAGE_LIMIT}) => {
      try {
        const res = await dispatch(UserActions.getAllUser(_data));
        setUsers(res.data.data);
      } catch (error) {
        console.log(error);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getAllUsers({
      page: 0,
      limit: GlobalVariables.PAGE_LIMIT,
    });
  }, [getAllUsers]);

  return (
    <MainLayout>
      <Button className="mb-15 float-rgt comnBtn" onClick={gotoAddUser}>
        Add more
      </Button>
      <UserList
        users={users}
        getAllUser={getAllUsers}
        disableUser={disableUser}
        deleteUser={deleteUser}
      />
    </MainLayout>
  );
};

export default withRouter(User);
