import React, { useEffect, useRef, useState } from "react";
import { Accordion, Icon, Pagination } from "semantic-ui-react";
import { FaqActions } from "../../../redux/_actions";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import ModalConfirmation from "../../../components/modals/ModalConfirmation";
import { toast } from "../../../components/Toast/Toast";
import GlobalVariables from "../../../_constants/GlobalVariables";
import DragDropFacadeService from "../../../Services/DragDropFacade.service";

const FaqList = ({ list, history, getFaq, totalCount, setFaq }) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFaq, setSelectFaq] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [render, setRender] = useState(false);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;

    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };
  const setChoice = (choice) => {
    if (choice) {
      deleteFaq(selectedFaq);
    }
  };
  const checkDelete = (id) => {
    setSelectFaq(id);
    setShowDeleteModal(true);
  };

  const deleteFaq = async (_id) => {
    try {
      const res = await dispatch(FaqActions.deleteFaq({ _id: _id }));
      getFaq({});
      toast.success(res.data.message);
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };

  const editHandler = (faqData) => {
    history.push({
      pathname: `/updateFaq`,
      state: {
        faqQuestion: faqData.question,
        faqAnswer: faqData.answer,
        faqId: faqData._id,
      },
    });
  };

  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage - 1;
    getFaq({ page: page, limit: GlobalVariables.PAGE_LIMIT });
  };

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const dragDropFaqOrder = async (list, copyListItems) => {
    console.log({ list });
    try {
      const apiData = {
        data: list,
      };
      await dispatch(FaqActions.dragDropFaq(apiData));
      setFaq(copyListItems);
    } catch (error) {
      console.log(error);
    }
  };

  const drop = async (e) => {
    let { apiList, newList } = DragDropFacadeService.dropAndCreateApiData(
      dragItem.current,
      dragOverItem.current,
      list
    );
    dragItem.current = null;
    dragOverItem.current = null;
    await dragDropFaqOrder(apiList, newList);
  };

  useEffect(() => {
    console.log(
      list.map((x, index) => {
        return { id: x._id, question: x.question, index: index + 1 };
      })
    );
    setRender(!render);
  }, [list]);

  return (
    <>
      <Accordion className="accordionSec">
        {list.length > 0
          ? list.map((obj, currentIndex) => (
              <div className="wrapper" key={currentIndex}>
                <Accordion.Title
                  active={activeIndex === currentIndex}
                  index={currentIndex}
                  onClick={handleClick}
                  draggable
                  onDragStart={(e) => dragStart(e, currentIndex)}
                  onDragEnter={(e) => dragEnter(e, currentIndex)}
                  onDragEnd={(e) => drop(e)}
                >
                  <Icon name="list" />

                  {obj.question}
                  <Icon name="dropdown" />
                  <div className="icons">
                    <Icon name="edit" onClick={() => editHandler(obj)} />

                    <Icon name="delete" onClick={() => checkDelete(obj._id)} />
                    <ModalConfirmation
                      title="Delete Your Account"
                      desc="Are you sure you want to delete"
                      btnText="deleteUser"
                      size="mini"
                      open={showDeleteModal}
                      setChoice={setChoice}
                      setOpen={setShowDeleteModal}
                    />
                  </div>
                </Accordion.Title>

                <Accordion.Content
                  active={activeIndex === currentIndex}
                  dangerouslySetInnerHTML={{ __html: `${obj.answer}` }}
                ></Accordion.Content>
              </div>
            ))
          : "no records found"}
      </Accordion>
    </>
  );
};

export default withRouter(FaqList);
