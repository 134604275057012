import React, { useState } from "react";
import ModalConfirmation from "../../../../components/modals/ModalConfirmation";
import { Breadcrumb, Button, Icon, Pagination, Table } from "semantic-ui-react";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import "./usersList.scss";
import { withRouter } from "react-router";
import { reduxForm } from "redux-form";

const UserList = ({ users, getAllUser, disableUser, deleteUser, history }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const formatWalletAddress = (walletAddress) => {
    if(!!walletAddress) {
      const firstFour = walletAddress.substring(0, 4);
      const lastSix = walletAddress.substring(36, 42);
      return `${firstFour}...${lastSix}`;
    } else {
      return null
    }
   
  };
  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage;
    getAllUser({ page: page-1, limit: GlobalVariables.PAGE_LIMIT });
  };

  const setChoice = (choice) => {
    if (choice) {
      deleteUser(selectedUser);
    }
  };

  const checkDelete = (data) => {
    setSelectedUser(data);
    setShowDeleteModal(true);
  };
  const updateUserInfo = (row) => {
    history.push(`/users/updateUserInfo/${row._id}`);
  };

  return (
    <div>
      <Table celled className="usersList">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Phone No.</Table.HeaderCell>
            <Table.HeaderCell>WalletAddress</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.list.length > 0
            ? users.list.map((row) => (
                <Table.Row>
                  <Table.Cell>{row.email}</Table.Cell>
                  <Table.Cell>{row.phoneNumber || "N/A"}</Table.Cell>
                  <Table.Cell>
                    {formatWalletAddress(row.walletAddress)}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      className={!row.isDisabled ? "disable" : "BtnDisabled"}
                      onClick={() => disableUser(row)}
                    >
                      {row.isDisabled ? "Disabled" : "Disable"}
                    </Button>
                  </Table.Cell>
                  <Table.Cell>
                    {/* <Icon
                      name="edit"
                      color="blue"
                      className="mr-10"
                      onClick={() => updateUserInfo(row)}
                    /> */}
                    <Icon
                      color="red"
                      name="trash"
                      onClick={() => checkDelete(row)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            : "no records found"}
        </Table.Body>
      </Table>
      {users.totalCounts >= GlobalVariables.PAGE_LIMIT && (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={1}
          ellipsisItem={{
            content: <Icon name="ellipsis horizontal" />,
            icon: true,
          }}
          firstItem={{ content: <Icon name="angle doubreduxFormle left" />, icon: true }}
          lastItem={{ content: <Icon name="angle double right" />, icon: true }}
          prevItem={{ content: <Icon name="angle left" />, icon: true }}
          nextItem={{ content: <Icon name="angle right" />, icon: true }}
          totalPages={
            Math.ceil(
              parseInt(users.totalCounts) / GlobalVariables.PAGE_LIMIT
            ) || 1
          }
        />
      )}
      <ModalConfirmation
        title="Delete Your Account"
        desc="Are you sure you want to delete your account"
        btnText="deleteUser"
        size="mini"
        open={showDeleteModal}
        setChoice={setChoice}
        setOpen={setShowDeleteModal}
      />
    </div>
  );
};

 export default withRouter(
  reduxForm({ form: "AddUserForm", enableReinitialize: true })(UserList)
);
