import React, { Component } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import packageJson from '../../../package.json';
import Login from "../../pages/public/login/Login";

class PublicRoutes extends Component {
  state = {};

  componentDidMount = async () => {
    localStorage.setItem('REACT_CACHE', packageJson.version);
  }

  render() {
    return (
      <div className="PublicArea__content">
        <Route path="/" component={Login} exact />
      </div>
    );
  }
}

export default withRouter(PublicRoutes);
