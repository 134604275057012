import React, { Component } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import { PersistActions } from "../../redux/_actions";


const Header = () => {
  const dispatch = useDispatch();

  const logoutUser = () => {
    const { logout } = PersistActions;
    dispatch(logout());
  }

  return (
    <React.Fragment>
      <div className="headerTop">
        <div className="headerRight">
         
          <ul className="navRight">
            <li className="navR_item navR_logedinOut">
              <Link to="/" onClick={logoutUser}>
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

export default withRouter(Header);
