import React, { useEffect, useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { toast } from "../Toast/Toast";

import "./modal.scss";

function ModalMessage({
  title,
  desc,
  size,
  open,
  setOpen,
  setChoice,
  setMessage,
  currentChoice
}) {
  const [value, setValue] = useState("");
  const [modalChoice, setModalChoice] = useState(false);

  const confirm = () => {
    setMessage(value);
    setModalChoice(currentChoice);
    setOpen(false);
    // if(value===""){
    //   toast.error("Enter a message");
    // }else{
    // }
  };
  const close = () => {
    setModalChoice(false);
    setOpen(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    setChoice(modalChoice);
  }, [modalChoice]);

  return (
    <Modal open={open} size={size}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>{desc}</Modal.Content>

      <input
        type="text"
        placeholder="message"
        onChange={handleChange}
        value={value}
      />

      <Modal.Actions>
        <Button className="comnBtn" onClick={() => confirm()}>
          Confirm
        </Button>
        <Button content="Cancel" onClick={() => close()} />
      </Modal.Actions>
    </Modal>
  );
}

export default ModalMessage;
