const START_LOADING = "[LOADING] START_LOADING";
const STOP_LOADING = "[LOADING] STOP_LOADING";

const SAVE_LOGIN_DETAILS = "[AUTH] SAVE_LOGIN_DETAILS";
const RESET_PERSIST_ON_LOGOUT = "[AUTH] RESET_PERSIST_ON_LOGOUT";
const SAVE_COLLECTION = "[COLLECTION] SAVE_COLLECTION";
const SAVE_BANNER_DETAILS = "[BANNER] SAVE_BANNER_DETAILS";
const GET_ALL_USER = "[USERS] GET_ALL_USER";
const USER_DETAILS = "[USER] USER_DETAILS";
const SAVE_COMMISSION = "[USER] SAVE_COMMISSION";

const Types = {
  START_LOADING,
  STOP_LOADING,
  SAVE_LOGIN_DETAILS,
  RESET_PERSIST_ON_LOGOUT,
  SAVE_COLLECTION,
  SAVE_BANNER_DETAILS,
  GET_ALL_USER,
  USER_DETAILS,
  SAVE_COMMISSION,
};

export default Types;
