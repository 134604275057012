import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Breadcrumb, Button, Icon, Pagination, Table } from "semantic-ui-react";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import { CollectionActions } from "../../../../redux/_actions";
import { useDispatch } from "react-redux";
import ModalMessage from "../../../../components/modals/ModalMessage";
import Enviroments from "../../../../_constants/Enviroment";

const CollectionList = ({ getCollections, collections, history }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFaq, setSelectFaq] = useState({});
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const goToAddNftpage = () =>
    history.push("/admin/panel/collection/create-collection");

  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage - 1;
    getCollections({ page: page, limit: GlobalVariables.PAGE_LIMIT });
  };
  const goToCollectionDetail = (collection) => {
    history.push(`/collection/details/${collection._id}`);
  };

  const setChoice = (choice) => {
    if (choice!=null) {
      console.log(message, " from setChoice");
      disableEnableCollection(selectedFaq);
    }
  };
  const checkDelete = (id) => {
    setSelectFaq(id);
    setShowDeleteModal(true);
  };

  const disableEnableCollection = async (_data) => {
    try {
      const data = { _id: _data._id, disableMessage: message };
      const res = await dispatch(
        CollectionActions.disableEnableCollection(data)
      );
      getCollections({ page: 0, limit: GlobalVariables.PAGE_LIMIT });
    } catch (error) {
      console.log(error);
    }
  };

  const getMailLink = (email) => {
    let emailLink = "";
    if (!email) return null;
    emailLink = "mailto:" + email;
    return <a href={emailLink}>{email}</a>;
  };

  const hideAddress = (address) => {
    console.log({ address });
    let newAddress = address.slice();
    return (
      newAddress.substring(0, 4) +
      newAddress.replace(/[a-z]*[0-9]*/gi, ".") +
      newAddress.substring(newAddress.length - 4)
    );
  };

  const createBlockchainExplorerLink = (blockChain, walletAddress) => {
    let link = "";
    if (!walletAddress) return "";
    link = `${GlobalVariables.EXPLORERS[blockChain]}/${walletAddress}`;
    return (
      <a href={link} target="_blank">
        {hideAddress(walletAddress)}
      </a>
    );
  };

  return (
    <div className="table-responsive">
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Block Chain</Table.HeaderCell>
            {/* <Table.HeaderCell>Created At</Table.HeaderCell> */}
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>External Link</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Contract Address</Table.HeaderCell>
            <Table.HeaderCell>Wallet Address</Table.HeaderCell>
            {/* <Table.HeaderCell>Is Approved</Table.HeaderCell> */}
            <Table.HeaderCell>Logo</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Royality</Table.HeaderCell>
            {/* <Table.HeaderCell>Status</Table.HeaderCell> */}
            {/* <Table.HeaderCell>Updated At</Table.HeaderCell> */}
            <Table.HeaderCell>Status</Table.HeaderCell>

            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {collections.list.length > 0 ? (
            collections.list.map((row, i) => (
              // (<TableRows isCollection={true} data={row} index={i} />)
              <Table.Row>
                <Table.Cell>{row.contract?.blockChain}</Table.Cell>
                {/* <Table.Cell>{row.createdAt}</Table.Cell> */}
                <Table.Cell>{row.description}</Table.Cell>
                <Table.Cell>{row.externalLink}</Table.Cell>
                <Table.Cell>{getMailLink(row.user?.email)}</Table.Cell>
                <Table.Cell>
                  {createBlockchainExplorerLink(
                    row.contract?.blockChain,
                    row.contract?.contractAddress
                  )}
                </Table.Cell>
                <Table.Cell>
                  {createBlockchainExplorerLink(
                    row.contract?.blockChain,
                    row.walletAddress
                  )}
                </Table.Cell>
                {/* <Table.Cell>{row.isApproved}</Table.Cell> */}
                <Table.Cell>{row.logo}</Table.Cell>
                <Table.Cell>{row.name}</Table.Cell>
                <Table.Cell>{row.royality}</Table.Cell>
                <Table.Cell>
                  <Button
                    // className={!row.isDisabled ? "disable" : "BtnDisabled"}
                    className={"BtnDisabled"}
                    onClick={() => checkDelete(row)}
                  >
                    {JSON.parse(row.isDisabled) ? "Disabled" : "Enabled"}
                  </Button>
                </Table.Cell>
                {/* <Table.Cell>{row.status}</Table.Cell> */}
                {/* <Table.Cell>{row.updatedAt}</Table.Cell> */}
                <Table.Cell>
                  <Icon
                    name="eye"
                    color="blue"
                    className="mr-10"
                    onClick={() => goToCollectionDetail(row)}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Cell collapsing colSpan="18" textAlign="center">
              <div
                style={{
                  textAlign: "center",
                  fontWeight: "bolder",
                  fontSize: "15px",
                }}
              >
                No Collections Found.
              </div>
            </Table.Cell>
          )}
        </Table.Body>
      </Table>

      <ModalMessage
        title="Disable Collection"
        desc="Reason to disable collection"
        btnText="deleteUser"
        size="mini"
        open={showDeleteModal}
        setMessage={setMessage}
        setChoice={setChoice}
        currentChoice={selectedFaq.isDisabled}
        setOpen={setShowDeleteModal}
      />

      {collections.totalCounts >= GlobalVariables.PAGE_LIMIT && (
        <Pagination
          onPageChange={handlePageClick}
          defaultActivePage={1}
          totalPages={
            Math.ceil(
              parseInt(collections.totalCounts) / GlobalVariables.PAGE_LIMIT
            ) || 1
          }
        />
      )}
    </div>
  );
};

export default withRouter(CollectionList);
