import { LoadingActions, PersistActions } from ".";
import ApiService from "../../Services/Api.service";

/**
 * @function addFaq
 * @param {question, answer}
 * @returns stores Qa in db
 */
export const addFaq = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions
  try {
    const { setHeaders } = PersistActions;
    await dispatch(startLoading())
    const headers = await dispatch(setHeaders(false));
    let res = await ApiService.addFaq(data, headers);
    await dispatch(stopLoading())
    return res;
  } catch (error) {
    await dispatch(stopLoading())
    throw error;
  }
};

/**
* @function showFaq
* @param {page, limit}
* @returns list of qa
*/
export const showFaq = (data) => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    await dispatch(startLoading());
    let res = await ApiService.showFaq(data);
    await dispatch(stopLoading());
    return res;
  } catch (error) {
    await dispatch(stopLoading());
    throw error;
  }
};

/**
* @function deleteFaq
* @param {faq id}
* @returns faq deleted
*/
export const deleteFaq = (_id) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    const { setHeaders } = PersistActions;
    await dispatch(startLoading());
    const headers = await dispatch(setHeaders(false));
    const res = await ApiService.deleteFaq(_id, headers);
    await dispatch(stopLoading());
    return res;
  } catch (error) {
    await dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function updateFaq
 * @param {question, answer}
 * @returns stores Qa in db
 */
export const updateFaq = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    const { setHeaders } = PersistActions;
    await dispatch(startLoading());
    const headers = await dispatch(setHeaders(false));
    const res = await ApiService.updateFaq(data, headers);
    await dispatch(stopLoading());
    return res;
  } catch (error) {
    await dispatch(stopLoading());
    throw error;
  }
};

export const dragDropFaq = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    const { setHeaders } = PersistActions;
    await dispatch(startLoading());
    const headers = await dispatch(setHeaders(false));
    const res = await ApiService.dragDropFaq(data, headers);
    await dispatch(stopLoading());
    return res;
  } catch (error) {
    await dispatch(stopLoading());
    throw error;
  }
};