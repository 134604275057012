import React, { useEffect, useState } from "react";
import { FaqActions } from "../../../redux/_actions";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import MainLayout from "../../../components/HOC/MainLayout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "../../../components/Toast/Toast";

const UpdateFaq = ({ history }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [questionData, setQuestion] = useState({
    question: location.state.faqQuestion,
    answer: location.state.faqAnswer,
    _id: location.state.faqId,
  });

  const questionHandler = (e) => {
    setQuestion({ ...questionData, question: e.target.value });
  };
  const answerHandler = (e) => {
    setQuestion({ ...questionData, answer: e });
  };

  const submitHandler = async (data) => {
    try {
      const { updateFaq } = FaqActions;
      let res = await dispatch(updateFaq(data));
      toast.success(res.data.message);
      history.push("/faq")
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };

  return (
    <div>
      <MainLayout>
        <Container fluid>
          <Container>
            <input
              type="text"
              placeholder="Question goes here."
              value={questionData.question}
              onChange={questionHandler}
              className="mb-20"
            />
            <ReactQuill
              theme="snow"
              value={questionData.answer}
              onChange={answerHandler}
            />
            <button className="comnBtn mt-20"
              onClick={() => {
                submitHandler(questionData);
              }}
            >
              Submit
            </button>
          </Container>
        </Container>
      </MainLayout>
    </div>
  );
};
export default withRouter(UpdateFaq);
