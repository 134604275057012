import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import "./modal.scss";

function ModalConfirmation({ title, desc, size, open, setOpen, setChoice }) {
  const confirm = (choice) => {
    setChoice(choice);
    setOpen(false);
  };
  return (
    <Modal open={open} size={size}>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>{desc}</Modal.Content>
      <Modal.Actions>
        <Button className="comnBtn" onClick={() => confirm(true)}>
          Yes
        </Button>
        <Button content="No" onClick={() => confirm(false)} />
      </Modal.Actions>
    </Modal>
  );
}

export default ModalConfirmation;
