import React from "react";

function ShowTerms({ terms }) {
  return (
    <>
      {terms && terms!='<p><br></p>' ? (
        <div style={{ marginTop: "20px" }}>
          <h2>Terms and Conditions :- </h2>
          <div dangerouslySetInnerHTML={{ __html: `${terms}` }}></div>
        </div>
      ):null}
    </>
  );
}

export default ShowTerms;
