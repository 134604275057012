class DragDropService {

  constructor() {
    this.dragPosition = -1;
    this.dragOverPosition = -1;
  }
  
  drop(list) {
    const items = [...list];
    const dragItemContent = items[this.dragPosition];
    items.splice(this.dragPosition, 1);
    items.splice(this.dragOverPosition, 0, dragItemContent);
    return items;
  }
  createDragDropApiData(list) {
    return list.map((x, index) => {
      return { _id: x._id, position: index + 1 };
    });
  }

  
}

export default DragDropService;
