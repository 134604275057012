import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import ApiService from "../../Services/Api.service";

/**
 * @function getHeaderValues
 * @param {*} data
 * @returns
 */
export const getHeaderValues = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  const { setHeaders } = PersistActions;
  try {
    dispatch(startLoading());
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getHeaderValues(data, headers);
    console.log({res})
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function addOrUpageHeaderLeftText
 * @param {*} data
 * @returns
 */
export const addOrUpageHeaderLeftText =
  (data) => async (dispatch, getState) => {
    const { startLoading, stopLoading } = LoadingActions;
    const { setHeaders } = PersistActions;
    try {
      dispatch(startLoading());
      const headers = await dispatch(setHeaders({ isFormdata: false }));
      let res = await ApiService.addOrUpageHeaderLeftText(data, headers);
      console.log({res})
      dispatch(stopLoading());
      toast.success(res.data.message)
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };
