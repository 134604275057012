import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Container } from "semantic-ui-react";
import { getTermsCondition } from "../../redux/_actions/terms.action";
import MainLayout from "../HOC/MainLayout";
import EditTerms from "./EditTerms";
import ShowTerms from "./ShowTerms";

function Terms() {
  const dispatch = useDispatch();
  const [terms, setTerms] = useState(null);

  const getTerms = async () => {
    try {
      let res = await dispatch(getTermsCondition());
      if (res.data.data && Object.keys(res.data.data).length != 0) {
        setTerms(res.data.data.text);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTerms();
  }, []);
  return (
    <>
      <MainLayout>
        <Container>
          <EditTerms terms={terms} setTerms={setTerms}/>
        </Container>
        <Container >
          <ShowTerms terms={terms}/>
        </Container>
      </MainLayout>
    </>
  );
}

export default Terms;
