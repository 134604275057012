import React, { useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { required, length } from "redux-form-validators";
import MainLayout from "../../../components/HOC/MainLayout";
import { FormField } from "../../../components/FormField";
import { withRouter } from "react-router";
import { CommissionAction } from "../../../redux/_actions";
import { compose } from "redux";
import { toast } from "../../../components/Toast/Toast";

function Commission({ handleSubmit, change }) {
  const dispatch = useDispatch();
  const getCommission = async (data) => {
    try {
      const res = await dispatch(CommissionAction.getCommission(data));
      change('walletAddress',res.data.data.walletAddress)
    } catch (error) {
      console.log(error);
    }
  };
  const updateCommission = async (data) => {
    try {
      const res = await dispatch(CommissionAction.updateCommission(data));
      console.log(res);
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };
  const submitForm = (data) => {
    let { commission, walletAddress } = data;
    let newData = {
      commission: commission.toString(),
      walletAddress: walletAddress.toString(),
    };
    updateCommission(newData);
  };
  useEffect(() => {
    getCommission();
  }, []);
  return (
    <MainLayout>
      <h2>Commission</h2>
      <Form onSubmit={handleSubmit(submitForm)}>
        <Form.Field>
          <label>Commission value</label>
          <Field
            name="commission"
            component={FormField}
            type="text"
            validate={[required()]}
          />
        </Form.Field>
        <Form.Field>
          <label>Wallet Address</label>
          <Field
            name="walletAddress"
            component={FormField}
            type="text"
            validate={[required(), length({ min: 42, max: 42 })]}
          />
        </Form.Field>
        <Form.Field>
          <Button type="submit">Submit</Button>
        </Form.Field>
      </Form>
    </MainLayout>
  );
}

const selector = (state) => ({
  initialValues: state.commission.adminCommission,
});

let CommissionForm = compose(
  connect(selector, null),
  reduxForm({
    form: "commission",
    enableReinitialize: true,
  })
)(Commission);

export default withRouter(CommissionForm);
