import axios from "axios";
import { toast } from "../components/Toast/Toast";
import * as CryptoJS from "crypto-js";
import Enviroments from "../_constants/Enviroment";
export { _fetch as fetch };

function handleError(error, reject) {
  if (!error) {
    toast.info("Something went wrong, Please login and try again.!!");
    // setTimeout(() => {
    //   window.localStorage.clear();
    //   window.location = "/";
    // }, 2000);
  } else if (error) {
     const { data: {errors:{message} } } = error;
     toast.error(message);
  }
  reject(error);
  return;
}

function handleResponse(successs, resolve) {
  resolve(successs);
  return;
}

function setMehod(method, path, body, options, encrypt, params) {
  let config = {};
  if (options) config.headers = options;

  params = params ? "?" + new URLSearchParams(params).toString() : "";
  if(encrypt){
    body = requestEncryption(body);
  }
  if (method === "get" || method === "delete") {
    // console.log(`${path}${params}`, config);
    return axios[method](`${path}${params}`, config);
  } else if (method === "post" || method === "put") {

    return axios[method](`${path}`, body, config);
  }
}

function _fetch(method, path, body, options, encrypt, params) {
  return new Promise((resolve, reject) => {
    return setMehod(method, path, body, options, encrypt, params)
      .then(function (response) {
        handleResponse(response, resolve);
        return;
      })
      .catch(function (error) {
        // return handleError(error);
        handleError(error.response, reject);
        return;
      });
  });
}

function requestEncryption(data) {
  let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), Enviroments.ENCRYPTION_KEY);
  return { data: ciphertext.toString() };
}


