import React, { useState } from "react";
import { FaqActions } from "../../../redux/_actions";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import MainLayout from "../../../components/HOC/MainLayout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import "./faq.scss";
import { toast } from "../../../components/Toast/Toast";
import { FormField } from "../../../components/FormField";

const Faq = ({ history }) => {

  const dispatch = useDispatch();
  const [questionData, setQuestion] = useState({ question: "", answer: "" });


  const questionHandler = (e) => {
    setQuestion({ ...questionData, question: e.target.value });
  }
  const answerHandler = (e) => {
    setQuestion({ ...questionData, answer: e });

  }

  const submitHandler = async () => {
    try {
      const { addFaq } = FaqActions;
      const res = await dispatch(addFaq(questionData));
      setQuestion({ question: "", answer: "" });
      toast.success(res.data.message);
      history.push("/faq")
    } catch (error) {
      toast.error(error);
      console.log(error);
    }

  };



  return (
    <div>
      <MainLayout>
        <Container fluid>
          <Container>
            <input className="form-control mb-20" component={FormField} type="text" placeholder="Question goes here." value={questionData.question} onChange={questionHandler} />
            <ReactQuill theme="snow" onChange={answerHandler} value={questionData.answer} />
            <button className="comnBtn mt-20" onClick={submitHandler}>Submit</button>
          </Container>
        </Container>
      </MainLayout>
    </div>
  );
};
export default withRouter((Faq));
