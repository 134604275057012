import Types from "../_actions/_types";

const initialState = {
  adminCommission: {
    commission: 0,
  },
};

const commission = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_COMMISSION:
      return { ...state, adminCommission: payload.adminCommission };
    default:
      return state;
  }
};

export default commission;
