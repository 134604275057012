import Types from "../_actions/_types";

const initialState = {
  bannerDetails: {}
};

const banner = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.SAVE_BANNER_DETAILS:
      return { ...state, bannerDetails: payload.bannerDetails};

    default:
      return state;
  }
};

export default banner;
