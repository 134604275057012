import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AuthGuard = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(state => state.persist.isLoggedIn);

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/admin/",
                state: {
                  from: props.location
                }
              }}
            />
          )
      }
    />
  );
};

export default AuthGuard;
