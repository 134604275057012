import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import ApiService from "../../Services/Api.service";
import collection from "../_reducers/collection.reducer copy";
import Types from "./_types";

export const saveCollection = (payload) => ({
  type: Types.SAVE_COLLECTION,
  payload,
});

/**
 * @function getCollections
 * @param {{ page, limit }} data
 * @returns /admin/getCollections/limit/page
 */
export const getCollections = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getCollections(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
export const getCollectionDetails = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getCollectionDetails(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

export const approveOrDeclineCollection =
  (data) => async (dispatch, getState) => {
    const { startLoading, stopLoading } = LoadingActions;
    try {
      dispatch(startLoading());
      const { setHeaders } = PersistActions;
      const headers = await dispatch(setHeaders({ isFormdata: false }));
      const res = await ApiService.approveOrDeclineCollection(data, headers);
      dispatch(stopLoading());
      return res;
    } catch (error) {
      dispatch(stopLoading());
      throw error;
    }
  };
/**
 * @function disableEnableCollection
 * @param {{ id }} data
 * @returns disable or enable
 */
  export const disableEnableCollection = (data) => async (dispatch, getState) => {
    const { startLoading, stopLoading } = LoadingActions;
    try {
      await dispatch(startLoading())
      const { setHeaders } = PersistActions;
      const headers = await dispatch(setHeaders({ isFormdata: false }));
      let res = await ApiService.disableEnableCollection(data, headers);
      await dispatch(stopLoading())
      toast.success(res.data.message);
      return res;
    } catch (error) {
      await dispatch(stopLoading())
      toast.error(error);
      throw error;
    }
  };

/**
 * @function getCollectionById
 * @param {{ id }} data
 * @returns get particular collection
 */
export const getCollectionById = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getCollectionById(data, headers);
    if (res) {
      const {
        data: { data },
      } = res;
      data.isFeatured = data.isFeatured === 0 ? false : true;
      dispatch(saveCollection({ collection: data }));
    }

    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function createCollection
 * @param {{ logo, banner, name, description }} data
 * @returns /admin/createCollection
 */
export const createCollection = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let { logo, banner, name, description, isFeatured, brandName, subHeading } =
      data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));

    logo = await dispatch(uploadFile(logo));
    if (!logo) return toast.info("Error: Uploading failed");
    banner = await dispatch(uploadFile(banner));
    if (!banner) return toast.info("Error: Uploading failed");

    data = {
      logo,
      banner,
      name,
      description,
      isFeatured,
      brandName,
      subTitle: subHeading,
    };
    const res = await ApiService.createCollection(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function updateCollection
 * @param {{ logo, banner, name, description }} data
 * @returns /admin/createCollection
 */
export const updateCollection = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;

  try {
    dispatch(startLoading());
    const { setHeaders, uploadFile } = PersistActions;
    let {
      id,
      logo,
      banner,
      name,
      description,
      isFeatured,
      brandName,
      subHeading,
    } = data;
    const headers = await dispatch(setHeaders({ isFormdata: false }));

    if (typeof banner !== "string") {
      banner = await dispatch(uploadFile(banner));
      if (!banner) return toast.info("Error: Uploading failed");
    }

    if (typeof logo !== "string") {
      logo = await dispatch(uploadFile(logo));
      if (!logo) return toast.info("Error: Uploading failed");
    }

    data = {
      id,
      logo,
      banner,
      name,
      description,
      isFeatured,
      brandName,
      subHeading,
    };
    const res = await ApiService.updateCollection(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};


/**
 * @function getCollectionNfts
 * @param {{ page, limit }} data
 * @returns /collection/details nfts
 */
 export const getCollectionNfts = (data) => async (dispatch, getState) => {
  try {
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getCollectionNfts(data, headers);
    return res;
  } catch (error) {
    throw error;
  }
};
