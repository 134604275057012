import Types from "../_actions/_types";

const initialState = {
  user: {},
};

const user = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.USER_DETAILS:
      return { ...state, user: payload.user };

    default:
      return state;
  }
};

export default user;
