import React, { useState } from "react";
import "../../public/login/login.scss";
import { useDispatch } from "react-redux";
import { UserActions } from "../../../redux/_actions";
import { Field, reduxForm } from "redux-form";
import { FormField } from "../../../components/FormField";
import { format, required, length } from "redux-form-validators";
import { withRouter } from "react-router";
import { Container, Form } from "semantic-ui-react";
import MainLayout from "../../../components/HOC/MainLayout";

const CreateUser = ({ handleSubmit, history }) => {
  const dispatch = useDispatch();

  const submitForm = async (data) => {
    console.log(data);
    const { addUser } = UserActions;
    await dispatch(addUser(data));
    history.push("/users");
  };

  return (
    <MainLayout>
      <Container fluid>
        <Container>
          <div>
            <div>
              <Form
                autoComplete="off"
                autoFocus="off"
                onSubmit={handleSubmit(submitForm)}
                noValidate
              >
                <Form.Field>
                  <label>Email</label>
                  <Field
                    component={FormField}
                    name="email"
                    type="email"
                    placeholder="Email email"
                    validate={[required()]}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Wallet Address</label>
                  <Field
                    component={FormField}
                    type="text"
                    name="walletAddress"
                    placeholder="Enter wallet"
                    validate={[required()]}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Country Code</label>
                  <select name="countryCode" id="cars">
                    <option value="+91">+91</option>
                    <option value="+1">+1</option>
                    <option value="+86">+86</option>
                  </select>
                </Form.Field>

                <Form.Field>
                  <label>Phone Number</label>
                  <Field
                    component={FormField}
                    type="input"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    validate={[
                      required(),
                      format({ with: "^[0-9]*$" }),
                      length({ is: 10 }),
                    ]}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Home Address</label>
                  <Field
                    component={FormField}
                    type="text"
                    name="address"
                    placeholder="Home Address"
                    validate={[required()]}
                  />
                </Form.Field>

                <Form.Field>
                  <button className="comnBtn" type="submit" primary="true">
                    Submit
                  </button>
                </Form.Field>
              </Form>
            </div>
          </div>
        </Container>
      </Container>
    </MainLayout>
  );
};
export default withRouter(
  reduxForm({ form: "AddUserForm", enableReinitialize: true })(CreateUser)
);
