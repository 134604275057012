import { LoadingActions, PersistActions } from ".";
import ApiService from "../../Services/Api.service";

/**
 * @function createTermsPolicy
 * @param terms
 * @returns stores terms in db
 */
export const createTermsPolicy = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions
  try {
    const { setHeaders } = PersistActions;
    await dispatch(startLoading())
    const headers = await dispatch(setHeaders(false));
    let res = await ApiService.createTermsPolicy(data, headers);
    await dispatch(stopLoading())
    return res;
  } catch (error) {
    await dispatch(stopLoading())
    throw error;
  }
};

/**
* @function getTermsCondition
* @returns terms and conditions
*/
export const getTermsCondition = () => async (dispatch) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    await dispatch(startLoading());
    let res = await ApiService.getTermsCondition();
    await dispatch(stopLoading());
    return res;
  } catch (error) {
    await dispatch(stopLoading());
    throw error;
  }
};

