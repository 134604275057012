import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import ApiService from "../../Services/Api.service";
import Types from "./_types";

/**
 * @function login
 * @param {email, password} data
 * @returns /admin/login Api response
 */
export const login = (_data) => async (dispatch) => {
  try {
    const { saveLoginDetails } = PersistActions;
    let res = await ApiService.login(_data);
    if (res) {
      const {
        data: {
          data: { token, user:{role} },
          message,
        },
      } = res;
      /** Save login details */
      if(role==="ADMIN"){
        toast.success(message);
        dispatch(saveLoginDetails({ user: {}, token: token }));
      } else {
        toast.error("You are not authorized to access")
      }
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

/**
 * @function getAllUser
 * @param {page ,limit} data
 * @returns users response
 */
export const getAllUser = (data) => async (dispatch, getState) => {
  try {
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    return await ApiService.getAllUser(data, headers);
  } catch (error) {
    throw error;
  }
};
export const disableUser = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    await dispatch(startLoading())
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    let res = await ApiService.disableUser(data, headers);
    await dispatch(stopLoading())
    toast.success(res.data.message);
    return res;
  } catch (error) {
    await dispatch(stopLoading())
    toast.error(error);
    throw error;
  }
};
export const deleteUser = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    await dispatch(startLoading())
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    let res = await ApiService.deleteUser(data, headers);
    await dispatch(stopLoading())
    toast.success(res.data.message);
    return res;
  } catch (error) {
    await dispatch(stopLoading())
    toast.error(error);
    throw error;
  }
};

/**
 * @function addUser
 * @param {email, walletAddress}
 * @returns /
 */
export const addUser = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    const { setHeaders } = PersistActions;
    await dispatch(startLoading());
    const headers = await dispatch(setHeaders(false));
    let res = await ApiService.addUser(data, headers);
    await dispatch(stopLoading());
    return res;
  } catch (error) {
    console.log(error);
    await dispatch(stopLoading());
    throw error;
  }
};

/**
 * @function updateUserInfo
 * @param {userId}
 * @returns {updated User Info}
 */

export const updateUserInfo = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    await dispatch(startLoading())
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    let res = await ApiService.updateUserInfo(data, headers);
    await dispatch(stopLoading())
    toast.success(res.data.message);
    return res;
  } catch (error) {
    await dispatch(stopLoading())
    toast.error(error);
    throw error;
  }
};

/**
 * @function getUserInfo
 * @param {userId}
 * @returns userDetails
 */

export const getUserDetails = (data) => async (dispatch, getState) => {
  try {
    let res = await ApiService.getUserDetails(data);
    dispatch(saveUserInfo({ user: res.data.data }));
    return res;
  } catch (error) {
    throw error;
  }
};

export const saveUserInfo = (data) => ({
  type: Types.USER_DETAILS,
  payload: data,
});
