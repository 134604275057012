import { LoadingActions, PersistActions } from ".";
import ApiService from "../../Services/Api.service";
import { stopLoading } from "./loading.action";
import Types from "./_types";

const saveAdminCommission = (data) => ({
  type: Types.SAVE_COMMISSION,
  payload: data,
});

export const getCommission = (data) => async (dispatch, getState) => {
  const { startLoading, stopLoading } = LoadingActions;
  try {
    dispatch(startLoading());
    const { setHeaders } = PersistActions;
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.getCommission(data, headers);
    await dispatch(
      saveAdminCommission({
        adminCommission: {
          commission: res.data.data.commission
        },
      })
    );

    await dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
export const updateCommission = (data) => async (dispatch, getState) => {
  const { setHeaders } = PersistActions;
  const { startLoading, stopLoading } = LoadingActions;
  try {
    const headers = await dispatch(setHeaders({ isFormdata: false }));
    const res = await ApiService.updateCommission(data, headers);
    dispatch(stopLoading());
    return res;
  } catch (error) {
    dispatch(stopLoading());
    throw error;
  }
};
