import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Button, Grid, Pagination, Table, Image } from "semantic-ui-react";
import MainLayout from "../../../../components/HOC/MainLayout";
import { CollectionActions } from "../../../../redux/_actions";
import "./collectionDetail.scss";
import GlobalVariables from "../../../../_constants/GlobalVariables";
import { toast } from "../../../../components/Toast/Toast";

function CollectionDetails() {
  const dispatch = useDispatch();
  const { collectionId } = useParams();
  const [collection, setCollection] = useState({
    blockChain: "",
    createdAt: "",
    description: "",
    externalLink: "",
    status: "PENDING",
    name: "",
  });
  const [nft, setNft] = useState({});

  const getCollectionDetails = async (collectionId) => {
    try {
      const res = await dispatch(
        CollectionActions.getCollectionDetails({ id: collectionId })
      );
      setCollection(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCollectionNfts = async (data) => {
    try {
      const nfts = await dispatch(CollectionActions.getCollectionNfts(data));
      console.clear();
      console.log("fetch", nfts.data.data);
      setNft(nfts.data.data);
      console.log(nft, "nfts");
    } catch (error) {
      console.log(error);
    }
  };

  const approveOrDeclineCollection = async (status) => {
    try {
      const data = { _id: collectionId, status: status };

      const res = await dispatch(
        CollectionActions.approveOrDeclineCollection(data)
      );
      toast.success(res.data.message);
      setCollection(res.data.data);
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };
  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage - 1;
    getCollectionNfts({
      page: page,
      limit: GlobalVariables.PAGE_LIMIT,
      collection: collectionId,
    });
  };

  useEffect(() => {
    getCollectionDetails(collectionId);
    getCollectionNfts({
      page: 0,
      limit: GlobalVariables.PAGE_LIMIT,
      collection: collectionId,
    });
  }, [collectionId]);

  return (
    <MainLayout>
      {collection && Object.keys(collection).length > 0 && (
        <>
          <h2>Collection details</h2>
          <ul className="collectionItems">
            {collection.blockChain && (
              <li>
              <label>BlockChain</label>
              <p>{collection.blockChain.blockChain}</p>
            </li>
            )}
            
            <li>
              <label>CreatedAt</label>
              <p>{collection.createdAt}</p>
            </li>
            <li>
              <label>Description</label>
              <p>{collection.description}</p>
            </li>
            <li>
              <label>ExternalLink</label>
              <p>{collection.externalLink}</p>
            </li>
            <li>
              <label>Status</label>
              <p>{collection.status}</p>
            </li>
            <li>
              <label>Name</label>
              <p>{collection.name}</p>
            </li>
            {collection.status === "PENDING" && (
              <li>
                <Button onClick={() => approveOrDeclineCollection("APPROVED")}>
                  Approved
                </Button>
                <Button onClick={() => approveOrDeclineCollection("DECLINED")}>
                  Disapproved
                </Button>
              </li>
            )}
          </ul>

          <Grid columns={4} style={{ marginTop: 60, marginBottom: 60 }}>
            {nft.totalCounts > 0 ? (
              <Grid.Row>
                {nft.list.map((row, i) => (
                  <Grid.Column style={{ marginBottom: 40 }}>
                    <Image src={row.url} />
                  </Grid.Column>
                ))}
              </Grid.Row>
            ) : (
              <p>No Nfts Found</p>
            )}
          </Grid>

          {nft.totalCounts >= GlobalVariables.PAGE_LIMIT && (
            <Pagination
              onPageChange={handlePageClick}
              defaultActivePage={0}
              totalPages={
                Math.ceil(
                  parseInt(nft.totalCounts) / GlobalVariables.PAGE_LIMIT
                ) || 1
              }
            />
          )}
          {/* <getCollectionDetails getCollectionDetails={getCollectionDetails} /> */}
        </>
      )}
    </MainLayout>
  );
}

export default CollectionDetails;
