import { LoadingActions, PersistActions } from ".";
import { toast } from "../../components/Toast/Toast";
import ApiService from "../../Services/Api.service";
import { setHeaders } from "./persist.action";

/**
 * @function getCollections
 * @param {{ page, limit }} data 
 * @returns /admin/getCollections/limit/page 
 */
export const getnfts = (data) => async (dispatch, getState) => {
    const { startLoading, stopLoading } = LoadingActions;

    try {
        dispatch(startLoading());
        const { setHeaders } = PersistActions;
        const headers = await dispatch(setHeaders({ isFormdata: false }));
        const res = await ApiService.getNfts(data, headers);
        dispatch(stopLoading());
        return res;
    } catch (error) {
        dispatch(stopLoading());
        throw error;
    }
}

/**
 * @function createCollection
 * @param {{ logo, banner, name, description }} data 
 * @returns /admin/createCollection
 */
export const createNft = (data) => async (dispatch, getState) => {
    const { startLoading, stopLoading } = LoadingActions;

    try {
        dispatch(startLoading());
        const { setHeaders, uploadFile } = PersistActions;
        let { logo, banner, name, description, collectionId, supply } = data;
        const headers = await dispatch(setHeaders({ isFormdata: false }));

        logo = await dispatch(uploadFile(logo));
        if (!logo) return toast.info("Error: Uploading failed"); 
        banner = await dispatch(uploadFile(banner));
        if (!banner) return toast.info("Error: Uploading failed"); 
        
        data = { logo, banner, name, description, collectionId, supply };
        const res = await ApiService.createNft(data, headers);
        dispatch(stopLoading());
        return res;
    } catch (error) {
        dispatch(stopLoading());
        throw error;
    }
}



export const getContracts = () => async (dispatch, getState) => {
    const { startLoading, stopLoading } = LoadingActions;

    try {
        dispatch(startLoading())
        const headers = await dispatch(setHeaders({ isFormdata: false })); 
        const res = await ApiService.getContracts(headers);
        dispatch(stopLoading());
        return res;
    } catch (error) {
        dispatch(stopLoading());
        throw error;
    }
}