import React, { useState } from "react";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import "../../pages/private/faq/faq.scss";
import { toast } from "../Toast/Toast";
import {createTermsPolicy} from '../../redux/_actions/terms.action'

const EditTerms = ({ history, terms, setTerms}) => {
  const dispatch = useDispatch();

  const handleTerms = (e) => {
    setTerms(e);
  };

  const submitHandler = async () => {
    try {
      let data = {
        text:terms
      }
      const res = await dispatch(createTermsPolicy(data));
      toast.success(res.data.message);
    } catch (error) {
      toast.error(error);
      console.log(error);
    }
  };

  return (
    <div style={{marginTop:"20px"}}>
      <Container fluid>
        <Container>
          <ReactQuill theme="snow" onChange={handleTerms} value={terms} />
          <button className="comnBtn mt-20" onClick={submitHandler}>
            Submit
          </button>
        </Container>
      </Container>
    </div>
  );
};
export default withRouter(EditTerms);
