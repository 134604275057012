import React from "react";
import { Form, Radio, Modal, Button } from "semantic-ui-react";
import "./Form.scss";
import * as S3Services from "../../Services/S3.Services";
import { reduxForm } from "redux-form";
import { withRouter } from "react-router";
import { LoadingActions, Testimonials } from "../../redux/_actions";
import { useDispatch } from "react-redux";
import { toast } from "../Toast/Toast";
import bell from "../../images/no_image.jpg";
import GlobalVariables from "../../_constants/GlobalVariables";

function Forms({ handleSubmit, setOpen, editValue }) {
  const [details, setDetails] = React.useState({
    image: "",
    fileName: "",
    content: "",
    fullName: "",
    isShow: false,
  });
  const dispatch = useDispatch();
  const [image, showImage] = React.useState("");
  const [isShow, setIsShow] = React.useState(false);

  React.useEffect(() => {
    setDetails(editValue);
    setIsShow(editValue?.isShow);
  }, [editValue]);

  const onChangeImage = async (e) => {
    localStorage.setItem("isUploadImgae", false);
    const { startLoading, stopLoading } = LoadingActions;
    const file = e.target.files[0];

    if (file.size > GlobalVariables.FILE_SIZE_LIMIT) {
      toast.error("please select file size less then 1 MB.");
      return false;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async function (e) {
      let image = new Image();
      image.src = e.target.result;
      /* image.onload = async function () {
        var height = image.naturalHeight;
        var width = image.naturalWidth;
        if (height > 1920 || width > 500) {
          toast.error("dimension must be less then 1920*500");
          localStorage.setItem("isUploadImgae", true);
          return false;
        } else {
          showImage(e.target.result);
        }
      };
 */
      if (
        localStorage.getItem("isUploadImgae") == false ||
        localStorage.getItem("isUploadImgae") == "false"
      ) {
        toast.success("waiting for file upload");
        dispatch(startLoading());
        const data = await S3Services.s3FileUplaod(file, "testimonial");
        setDetails({
          ...details,
          fileName: data.key.split("/")[1],
          image: data.Location,
        });
        dispatch(stopLoading());
      }
    };
  };

  const handleChange = () => {
    setIsShow((previous) => !previous);
  };

  const submitForm = async () => {
    const { addTestimonials, updateTestimonial } = Testimonials;
    if (editValue?._id) {
      const record = await dispatch(
        updateTestimonial(editValue?._id, {
          ...details,
          isShow: isShow || false,
        })
      );
      toast.success(record.data.message);
    } else {
      const record = await dispatch(
        addTestimonials({ ...details, isShow: isShow || false })
      );
      toast.success(record.data.message);
    }
    setOpen(false);
    setDetails({
      image: "",
      fileName: "",
      content: "",
      fullName: "",
      isShow: false,
    });
  };

  return (
    <div className="form-input">
      <Form
        autoComplete="off"
        autoFocus="off"
        onSubmit={handleSubmit(submitForm)}
        noValidate
      >
        <Modal.Content image>
          <div className="imgSec">
            <img
              size="medium"
              src={image ? image : details?.image ? details?.image : bell}
              style={{ height: "300px", width: "300px" }}
              wrapped
            />
            <input
              type="file"
              id="myFile"
              name="filename"
              onChange={onChangeImage}
            />
          </div>
          <Modal.Description>
            <div className="form-input">
              <Form>
                <Form.Input
                  label="Name"
                  placeholder="Name"
                  onChange={(e) =>
                    setDetails({ ...details, fullName: e.target.value })
                  }
                  value={details?.fullName}
                />
                <Form.TextArea
                  label="Content"
                  placeholder="Tell us more about you..."
                  onChange={(e) =>
                    setDetails({ ...details, content: e.target.value })
                  }
                  value={details?.content}
                />

                <Form.Group>
                  <label>
                    <b>Publish</b>
                  </label>
                  <br />
                  <Radio toggle checked={isShow} onChange={handleChange} />
                </Form.Group>
              </Form>
            </div>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <div className="mainBlock flex">
            <Button className="ui button" type="submit">
              submit
            </Button>
          </div>
        </Modal.Actions>
      </Form>
    </div>
  );
}

// export default Forms;

export default withRouter(
  reduxForm({ form: "AddTestimonialForm", enableReinitialize: true })(Forms)
);
