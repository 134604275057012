import React, { useEffect, useState } from "react";
import { Button, Modal, Icon, Pagination } from "semantic-ui-react";
import "./Testimonial.scss";
import GlobalVariables from "../../_constants/GlobalVariables";
import MainLayout from "../HOC/MainLayout";
import { LoadingActions, Testimonials } from "../../redux/_actions";
import { useDispatch } from "react-redux";
import ModalConfirmation from "../modals/ModalConfirmation";
import { toast } from "../Toast/Toast";
import Forms from "../form/Forms";
import bell from "../../images/no_image.jpg";
import { updateTestimonial } from "../../redux/_actions/testimonials.action";

function Testimonial() {
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [selectTest, setselectTest] = React.useState("");

  const [listData, setListData] = useState([]);
  const [selectedListData, setSelectedListData] = useState([]);
  const [editValue, setEditValue] = React.useState(null);

  const dispatch = useDispatch();

  const getListTestimonials = async (data) => {
    const { getTestimonials } = Testimonials;
    const res = await dispatch(getTestimonials(data));
    setCount(res.data.data[0].count);
    setListData(res.data.data[0].list);
  };

  const getSelectedTestimonials = async (data) => {
    const { getTestimonials } = Testimonials;
    const res = await dispatch(getTestimonials(data));
    setSelectedListData(res.data.data[0].list);
  };

  const checkDelete = (id) => {
    setselectTest(id);
    setShowDeleteModal(true);
  };

  const setChoice = (choice) => {
    if (choice) {
      deleteTestimonial(selectTest);
    }
  };

  const getEditValue = (data) => {
    setEditValue(data);
    setOpen(true);
  };

  const changeModalState = (stateVal) => {
    setOpen(stateVal);
    getListTestimonials({ isShow: false, page: "0", limit: "10" });
    getSelectedTestimonials({ isShow: true, page: "0", limit: "5" });
  };

  const deleteTestimonial = async (_id) => {
    const { startLoading, stopLoading } = LoadingActions;
    try {
      dispatch(startLoading());
      const res = await dispatch(Testimonials.deleteTestimonial(_id));
      getListTestimonials({ isShow: false, page: "0", limit: "10" });
      getSelectedTestimonials({ isShow: true, page: "0", limit: "5" });

      toast.success("Testimonial has been deleted successfully..!!");
      dispatch(stopLoading());
    } catch (error) {
      dispatch(stopLoading());
      toast.error(error);
    }
  };

  const handlePageClick = (e, pageInfo) => {
    let page = pageInfo.activePage;
    getListTestimonials({
      isShow: false,
      page: page - 1,
      limit: GlobalVariables.PAGE_LIMIT,
    });
  };

  useEffect(() => {
    getListTestimonials({ isShow: false, page: "0", limit: "10" });
  }, []);
  useEffect(() => {
    getSelectedTestimonials({ isShow: true, page: "0", limit: "5" });
  }, []);

  const updateTestimonial = async (data, type) => {
    const { startLoading, stopLoading } = LoadingActions;
    dispatch(startLoading());
    if (selectedListData.length >= 5) {
      toast.error(`Can't add more then 5`);
      return;
    }
    const { updateTestimonial } = Testimonials;
    const res = await dispatch(updateTestimonial(data, { isShow: type }));
    if (res) {
      if (type) {
        toast.success("Testimonial Published");
      } else {
        toast.success("Testimonial moved to draft");
      }

      getListTestimonials({ isShow: false, page: "0", limit: "10" });
      getSelectedTestimonials({ isShow: true, page: "0", limit: "5" });
    }
    dispatch(stopLoading());
  };

  return (
    <MainLayout>
      <div className="testimonial-page ">
        <div class="headSec">
          <h2>Testimonial</h2>
          <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button onClick={() => setEditValue(null)}>Create</Button>}
            className="testimonial-modal"
          >
            <Forms setOpen={changeModalState} editValue={editValue} />
          </Modal>
        </div>

        <div class="ui grid">
          <div class="eight wide column hrline ">
            <h3>Draft</h3>

            {listData.map((row) => (
              <div class="checkinputSec">
                <img
                  class="checkImage"
                  src={row.image || bell}
                  alt="checkImage"
                />
                <div>
                  <h2>{row.fullName}</h2>
                  <p>{row.content}</p>
                  <div className="buttons">
                    <button
                      class="ui button mr-10"
                      onClick={() => getEditValue(row)}
                    >
                      Edit
                    </button>
                    <button
                      class="ui button mr-10"
                      onClick={() => checkDelete(row._id)}
                    >
                      Delete
                    </button>
                    <button
                      class="ui button mr-10"
                      onClick={() => updateTestimonial(row._id, true)}
                    >
                      Publish
                    </button>
                  </div>
                </div>
              </div>
            ))}
            {count >= GlobalVariables.PAGE_LIMIT && (
              <Pagination
                onPageChange={handlePageClick}
                defaultActivePage={1}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle doubreduxFormle left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                totalPages={1}
              />
            )}
          </div>
          <div class="eight wide column">
            <h3>Published</h3>
            {selectedListData.map((row) => (
              <div class="checkinputSec">
                <img class="checkImage" src={row.image} alt="checkImage" />
                <div>
                  <h2>{row?.fullName}</h2>
                  <p>
                    {row?.content?.length > 200
                      ? row?.content.substring(0, 150) + "..."
                      : row?.content}
                  </p>
                  <button
                    class="ui button mr-10"
                    onClick={() => getEditValue(row)}
                  >
                    Edit
                  </button>
                  <button
                    class="ui button mr-10"
                    onClick={() => checkDelete(row._id)}
                  >
                    Delete
                  </button>
                  <button
                    class="ui button mr-10"
                    onClick={() => updateTestimonial(row._id, false)}
                  >
                    Move to Draft
                  </button>
                </div>
                <div></div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <ModalConfirmation
        title="Delete testimonial"
        desc="Are you sure you want to delete testimonial ?"
        btnText="deleteUser"
        size="mini"
        open={showDeleteModal}
        setChoice={setChoice}
        setOpen={setShowDeleteModal}
      />
    </MainLayout>
  );
}

export default Testimonial;

// export default withRouter(
//   reduxForm({ form: "AddTestimonialForm", enableReinitialize: true })(
//     Testimonial
//   )
// );
