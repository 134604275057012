import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { logOut } from "../../redux/_actions/persist.action";

const NoGuard = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(state => state.persist.isLoggedIn);

  return (
    <Route
      {...rest}
      render={props =>
        !isLoggedIn ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
            />
          )
      }
    />
  );
};

export default NoGuard;
