const API_HOST = process.env.REACT_APP_API_HOST;
const DATA_ENCRYPT_KEY = process.env.REACT_APP_DATA_ENCRYPT_KEY;
const ALGOSIGNER_NETWORK = process.env.REACT_APP_ALGOSIGNER_NETWORK;
const ENCRYPTION_KEY = process.env.REACT_APP_ENCRYPTION_KEY;

const Enviroments = {
  API_HOST,
  DATA_ENCRYPT_KEY,
  ALGOSIGNER_NETWORK,
  ENCRYPTION_KEY
};

export default Enviroments;
