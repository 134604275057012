import React from "react";
import "./LeftSideBlock.scss";
import { useSelector } from "react-redux";
import { withRouter } from "react-router";
import { Link, NavLink } from "react-router-dom";
import logo from "../../images/LOGO.svg";
import { Icon } from "semantic-ui-react";

const LeftSideBlock = () => {
  const persist = useSelector((state) => state.persist);
  const { isLeftbar } = persist;

  // Close the dropdown if the user clicks outside of it

  return (
    <div className={`leftBar ${isLeftbar ? "isShow" : "isHide"}`}>
      <div className="left_logoBlock">
        <Link to="/" title="Dashboard">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <div className="sidebarNav">
        <ul>
          <li>
            <NavLink exact to="/">
              {" "}
              Collections{" "}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/users">
              {" "}
              Users{" "}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/faq">
              {" "}
              Faq{" "}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/commission">
              {" "}
              Commission{" "}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/contracts">
              {" "}
              Contracts{" "}
            </NavLink>
          </li>
          <li>
            <NavLink exact to="/terms">
              Terms and Conditions{" "}
            </NavLink>
          </li>

          <li>
            <NavLink to="">
              Homepage <Icon name="caret down"></Icon>
            </NavLink>
            <ul className="dropdown">
              <li>
                <NavLink to={"/homepage/headings"}>Headings</NavLink>
                <NavLink to={"/homepage/testimonials"}>Testimonials</NavLink>
              </li>
              <li></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withRouter(LeftSideBlock);
