import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Icon, Pagination, Table } from "semantic-ui-react";
import "../user/list/usersList.scss";
import { withRouter } from "react-router";
import { reduxForm } from "redux-form";
import MainLayout from "../../../components/HOC/MainLayout";
import { NftActions } from "../../../redux/_actions";
import { useDispatch } from "react-redux";

function ContractList() {
  const [contracts, setContracts] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getContracts();
  }, []);

  const getContracts = async () => {
    try {
      const { getContracts } = NftActions;
      let res = await dispatch(getContracts())
      setContracts(res.data.data);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MainLayout>
      <div>
        <Table celled className="usersList">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>BlockChain</Table.HeaderCell>
              <Table.HeaderCell>ChainId</Table.HeaderCell>
              <Table.HeaderCell>ProxyAddress</Table.HeaderCell>
              <Table.HeaderCell>ContractAddress</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {contracts && contracts.length > 0
              ? contracts.map((contract,index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{contract.blockChain}</Table.Cell>
                    <Table.Cell>{contract.chainId}</Table.Cell>
                    <Table.Cell>{contract.proxyAddress}</Table.Cell>
                    <Table.Cell>
                      {contract.contractAddress}
                    </Table.Cell>
                    
                  </Table.Row>
                ))
              : "no records found"}
          </Table.Body>
        </Table>
      </div>
    </MainLayout>
  );
}

export default ContractList
