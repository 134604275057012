import React from "react";
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import PublicRoutes from "./PublicRoutes/PublicRoute";
import AuthGuard from "./Guards/AuthGuard";
import NoGuard from "./Guards/NoGuard";
import PrivateRoutes from "./PrivateRoutes/PrivateRoutes";
import LoaderComponent from "../components/LoaderComponent/LoaderComponent.jsx";
import { useSelector } from "react-redux";

const Application = () => {
  const isLoggedIn = useSelector(state => state.persist.isLoggedIn);

  return (
    <>
      <LoaderComponent></LoaderComponent>
      <Router basename={"/"}>
        <Switch>
          {
            isLoggedIn ? 
            <AuthGuard path="/" component={PrivateRoutes} /> :
            <NoGuard path="/" component={PublicRoutes} />            
          }
        </Switch>
      </Router>
    </>
  );
}

export default Application;
