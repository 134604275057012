const PAGE_LIMIT = 10;
const FILE_SIZE_LIMIT = 1016001; // INTO BYTES
const INIT_COLLECTION_FILTERS = {
  collectionName: "",
  email: "",
  creationDate: "",
  releaseDate: "",
  isFiltered: false,
};

const EXPLORERS = {
  ETH: process.env.REACT_APP_ETH_EXPLORER_URL,
  MATIC: process.env.REACT_APP_MATIC_EXPLORER_URL,
  BSC: process.env.REACT_APP_BSC_EXPLORER_URL,
};
const GlobalVariables = {
  PAGE_LIMIT,
  INIT_COLLECTION_FILTERS,
  EXPLORERS,
  FILE_SIZE_LIMIT,
};

export default GlobalVariables;
