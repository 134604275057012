import React, { useEffect, useState } from "react";
import "../../public/login/login.scss";
import { FaqActions } from "../../../redux/_actions";
import { withRouter } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { Container, Accordion, Icon } from "semantic-ui-react";
import MainLayout from "../../../components/HOC/MainLayout";
import { useDispatch } from "react-redux";
import FaqList from "./FaqList";

const ShowFaq = ({ history }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getFaq = async (data) => {
    try {
      const res = await dispatch(FaqActions.showFaq(data));
      setData(res.data.data.list);
      setTotalCount(res.data.data.totalCounts);
    } catch (error) {}
  };
  useEffect(() => {
    getFaq({});
  }, []);

  return (
    <div>
      <MainLayout>
        <Container fluid>
          <Container>
            <button
              component={Link}
              onClick={() => history.push("/addFaq")}
              className="comnBtn"
            >
              Add Faq{" "}
            </button>
            {data && data.length > 0 && (
              <FaqList list={data} setFaq={setData} getFaq={getFaq} totalCount={totalCount} />
            )}
          </Container>
        </Container>
      </MainLayout>
    </div>
  );
};

export default withRouter(ShowFaq);
