import * as AWS from "aws-sdk";

export const s3FileUplaod = async (file, folder) => {
  let s3 = null;

  //   this.initS3();
  // console.log(AWS.config);
  AWS.config.update({
    // apiVersion: "2006-03-01",
    accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ID,
    region: process.env.REACT_APP_S3_REGION,
  });
  s3 = new AWS.S3({ apiVersion: "2006-03-01" });

  return new Promise(async (resolve, reject) => {
    let selectedFile = file;
    const bucketName = "stage-spot-trade";
    const ext = selectedFile.name.split(".")[1];
    let fileName = selectedFile.name.split(".")[0].split(" ").join("-");
    fileName = `${fileName}-${new Date().getTime()}.${ext}`;
    // const fileBuffer = await this.toBase64(selectedFile);
    // console.log(fileBuffer);

    const params = {
      Bucket: bucketName,
      Key: !!folder ? folder + "/" + fileName : fileName,
      Body: selectedFile,
      ACL: "public-read",
      ContentType: file["mimetype"],
    };

    s3.upload(params, (error, data) => {
      if (error) reject(error); /* return error if any otherwise, return data */
      resolve(data);
    });
  });
};
