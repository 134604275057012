import React, { Children } from "react";
import "./mainLayout.scss";
import { useSelector } from "react-redux";
import LeftSideBlock from "../../components/LeftSide/LeftSideBlock";
import Header from "../../components/Header/Header";
import Mobileheader from "../../components/Mobileheader/Mobileheader";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";

const MainLayout = ({ children }) => {
  const persist = useSelector(state => state.persist);
  const { isLeftbar } = persist;

  return (
    <>
      {/* <div className="for_desk">
       
      </div> */}
      <div className="mainBlock">
        <LeftSideBlock isLeftbar={isLeftbar} />
        <div className={`rightSection ${isLeftbar ? "isShow" : "isHide"}`}>
        <Header isLeftbar={isLeftbar} />
          <div className="for_mobile">
            <Mobileheader />
          </div>
          <Container fluid style={{paddingTop:'50px', paddingBottom:'50px', paddingLeft:'25px', paddingRight:'25px'}}>
          { children }
          </Container>
        </div>
      </div>
    </>
  );
}


export default withRouter(MainLayout);
